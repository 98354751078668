<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="Wrapper">
        <el-form
          label-position="left"
          :inline="true"
          label-width="auto"
          :model="formInline"
          style="width: calc(100% - 376px); float: left; overflow: hidden"
          :ref="'form'"
        >
          <div class="title-text fw600">道路信息</div>
          <el-form-item label="道路层级:" prop="slaveRelation">
            <span style="line-height: 40px; color: #666">{{
              ["道路", "道路", "路段"][formInline.slaveRelation]
            }}</span>
          </el-form-item>
          <el-form-item
            label="关联道路:"
            :prop="formInline.slaveRelation == 2 ? 'parentId' : ''"
            v-if="formInline.slaveRelation == 2"
          >
            <span style="line-height: 40px; color: #666">{{ formInline.parentParkName }}</span>
          </el-form-item>
          <el-form-item :label="slaveRelationDesc + '名称:'" prop="parkName">
            <span style="line-height: 40px; color: #666">{{ formInline.parkName }}</span>
          </el-form-item>
          <el-form-item :label="slaveRelationDesc + '编码:'" prop="parkAlias">
            <span style="line-height: 40px; color: #666">{{ formInline.parkAlias }}</span>
          </el-form-item>
          <el-form-item
            :label="slaveRelationDesc + '物理类型:'"
            prop="parkType"
            style="display: inline-block"
          >
            <span style="line-height: 40px; color: #666">{{ formInline.parkTypeName }}</span>
          </el-form-item>
          <el-form-item label="系统编码: " prop="parkCode">
            <span style="line-height: 40px; color: #666">{{ formInline.parkCode }}</span>
          </el-form-item>
          <el-form-item label="泊位数:" :prop="formInline.slaveRelation != 1 ? 'amount' : ''">
            <span style="line-height: 40px; color: #666">{{ formInline.amount }}</span>
          </el-form-item>
          <el-form-item label="区域:" prop="areaId">
            <span style="line-height: 40px; color: #666">{{ formInline.areaName }}</span>
          </el-form-item>
          <el-form-item label="经纬度:" prop="longitude">
            <span style="line-height: 40px; color: #666">{{ lnglat }}</span>
          </el-form-item>
          <el-form-item label="是否支持充电: " prop="parkCode">
            <span style="line-height: 40px; color: #666">{{
              formInline.chargeable == 0 ? "否" : formInline.chargeable == 1 ? "是" : ""
            }}</span>
          </el-form-item>
          <el-form-item label="地址:" prop="parkAddress" class="address-box">
            <span style="line-height: 40px; color: #666">{{ formInline.parkAddress }}</span>
          </el-form-item>
          <el-form-item label="接入方式: " prop="parkAccessTypeName">
            <span style="line-height: 40px; color: #666">{{ formInline.parkAccessTypeName }}</span>
          </el-form-item>
          <div class="title-text fw600">道路运营信息</div>
          <el-form-item label="所属商户:" prop="businessOwner">
            <span style="line-height: 40px; color: #666">{{ formInline.operationName }}</span>
          </el-form-item>
          <el-form-item label="是否商业车场:" prop="businessType">
            <span style="line-height: 40px; color: #666">{{ formInline.businessTypeName }}</span>
          </el-form-item>
          <el-form-item label="合作模式:" prop="cooperationModel">
            <span style="line-height: 40px; color: #666">{{
              formInline.cooperationModelName
            }}</span>
          </el-form-item>
          <el-form-item label="支付方式:" prop="payMode">
            <span style="line-height: 40px; color: #666">{{ formInline.payModeName }}</span>
          </el-form-item>

          <el-form-item label="商用状态:" prop="commercialStatus">
            <span style="line-height: 40px; color: #666">{{
              formInline.commercialStatusName
            }}</span>
          </el-form-item>
          <el-form-item label="道路状态:" prop="parkState">
            <span style="line-height: 40px; color: #666">{{ formInline.parkStateName }}</span>
          </el-form-item>
          <el-form-item
            label="计费类型:"
            prop="scopeId"
            v-if="$route.query.parkTypeName != '封闭车场'"
          >
            <span style="line-height: 40px; color: #666">{{ formInline.scopeName }}</span>
          </el-form-item>
          <!-- <el-form-item label="场内支付:" prop="innerPayable">
            <span style="line-height: 40px; color: #666">{{
              formInline.innerPayable == 1 ? "是" : "否"
            }}</span>
          </el-form-item> -->
          <el-form-item label="扩展支付场景:" prop="innerPayable">
            {{ getinnerPayable(formInline.innerPayable, formInline.selfParkingId) }}
          </el-form-item>
          <el-form-item label="自主停车:" prop="innerPayable" v-if="formInline.innerPayable == 3">
            {{ formInline.configName }}
          </el-form-item>
          <el-form-item
            label="免费驶离时长: "
            prop="innerPayTime"
            v-if="formInline.innerPayable == 1"
          >
            <span style="line-height: 40px; color: #666">{{ formInline.innerPayTime }}分钟</span>
          </el-form-item>
          <el-form-item
            label="收费规则描述: "
            prop="thirdFeeDesc"
            class="rule-box"
            v-if="formInline.thirdFeeDesc"
          >
            <span style="line-height: 40px; color: #666">{{ formInline.thirdFeeDesc }}</span>
          </el-form-item>
          <div class="title-text fw600">{{ slaveRelationDesc }}相关信息</div>
          <el-form-item label="泊位分布: " prop="berthDeploy">
            <span style="line-height: 40px; color: #666">{{
              formInline.berthDeploy == 1 ? "单侧" : formInline.berthDeploy == 2 ? "双侧" : ""
            }}</span>
          </el-form-item>
          <el-form-item label="所在道路长度: " prop="roadLength">
            <span v-if="formInline.roadLength" style="line-height: 40px; color: #666"
              >{{ formInline.roadLength }}米</span
            >
          </el-form-item>
          <el-form-item
            label="是否承包:"
            prop="contract"
            v-if="$route.query.parkTypeName == '封闭车场'"
          >
            <span style="line-height: 40px; color: #666">{{
              formInline.contractName == 0 ? "是" : "否"
            }}</span>
          </el-form-item>
          <el-form-item
            label="停车场开放时间:"
            prop="openStartTime"
            v-if="$route.query.parkTypeName == '封闭车场'"
          >
            <span style="line-height: 40px; color: #666">{{
              formInline.openStartTime + "~" + formInline.openStopTime
            }}</span>
          </el-form-item>
        </el-form>
        <div class="uploadPic">
          <img v-if="imageUrl" :src="imageUrl" class="avatar2" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import // dateFormat
"../../../../common/js/public.js";
export default {
  name: "hello",
  data() {
    return {
      openTime: "",
      fileChang: false,
      parkState: [],
      parkScope: [],
      key1: "",
      city: "",
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        parkId: "",
      },
      workStateAll: [],
      treeData: [],
      parkType: [],
      parkList: "",
      parkParent: [],
      butreeData: [],
      areaName: "",
      businessName: "",
      businessType: [],
      slaveRelation: [],
      cooperationModel: [],
      commercialStatus: [],
      imageUrl: "",
      payMode: [],
      isEdit: false,
      loading: false,
      effective: "1",
      startTime: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      slaveRelationDesc: "",
      formInline: {
        parkName: "",
        areaId: "",
        parkCode: "",
        slaveRelation: 1,
        innerPayable: "",
        selfParkingId: "",
        parentId: "",
        parkAddress: "",
        imageUrl: "",
        amount: "",
        parkType: "",
        businessOwner: "",
        bootTime: new Date(),
        parkState: "",
        mapGrade: "",
        payMode: "",
        cooperationModel: "",
        commercialStatus: 1,
        longitude: "",
        latitude: "",
        businessType: "",
        scopeId: "",
        contract: "",
        openStartTime: "",
        openStopTime: "",
        access: "", // 接入方式
      },
    };
  },
  methods: {
    getinnerPayable(innerPayable, selfParkingId) {
      if (innerPayable == 1 && selfParkingId > 0) {
        return "场中支付,自主停车";
        // 自主停车
      } else if (innerPayable == 2 && selfParkingId > 0) {
        return "自主停车";
        // 场中支付
      } else if (innerPayable == 1 && selfParkingId == 0) {
        return "场中支付";
      } else {
        return "无";
      }
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.formInline = data;
          this.areaName = data.areaName;
          this.businessName = data.operationName;
          this.lnglat =
            data.longitude && data.latitude
              ? data.longitude / 1000000 + "," + data.latitude / 1000000
              : "";
          this.openTime = [
            new Date(this.formInline.openStartTime),
            new Date(this.formInline.openStopTime),
          ];
          this.slaveRelationDesc = data.slaveRelation == 2 ? "路段" : "道路";
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImg(parkId) {
      let url = "/acb/2.0/park/getImagebyId/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.imageUrl = res.value;
          // this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  components: {},
  created() {
    this.getParkImg(this.$route.query.parkId);
    this.getParkDetail(this.$route.query.parkId);
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper {
  overflow: hidden;
  width: 100%;
  padding-right: 50px;
  box-sizing: border-box;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border-radius: 4px;
  padding: 20px;
  padding-bottom: 0;
}

.uploadPic {
  float: right;
  // margin-left: 150px;
  width: 368px;
  height: 225px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
  }
}
.fw600 {
  font-weight: 600;
}
.title-text {
  position: relative;
  font-size: 14px;
  color: #2F2F51;
  padding-left: 12px;
  // margin: 8px 0;
  margin-bottom: 8px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 15px;
    background: #0C66FF;
  }
}

/deep/ {
  .el-form-item {
    width: 48%;
    // display: inline-block;
    // padding-right: 4%;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }

    .el-form-item__label {
      color: #98A9BF;
    }
  }

  .el-form-item__label-wrap {
    margin-left: 0px !important;
  }

  .el-form-item__content {
    margin-left: 0px !important;
  }

  .address-box .el-form-item__content {
    margin-left: 44px !important;
  }

  .rule-box .el-form-item__content {
    margin-left: 100px !important;
  }

  .el-form--inline .el-form-item__content {
    display: block;
  }
}
</style>
<style>
.dialog_button {
  width: 100%;
  height: 40px;
}
</style>

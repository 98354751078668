<template>
  <div>
    <!--列表区域-->
    <div class="tableWrapper">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('list.operation')"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand($event, scope.row)">
              <el-button type="primary" size="small"> 操作 </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="c" v-if="authority.button.configure"
                  >配置</el-dropdown-item
                >
                <el-dropdown-item command="a" v-if="authority.button.edit"
                  >编辑</el-dropdown-item
                >
                <el-dropdown-item command="b" v-if="authority.button.view"
                  >查看</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页器-->
    <!-- <div class="pagerWrapper">
        <div class="block" style="text-align:right">
          <el-pagination  @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      authority: JSON.parse(sessionStorage.subPageAuthority),
      loading: false,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      tableData: [],
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.Road_section_name"),
          // label: this.$t("list.Road_section_name"),
          width: "",
        },
        {
          prop: "parkTypeName",
          label: this.$t("list.Road_section_type"),
          // label: this.$t("list.Road_section_type"),
          width: "",
        },
        {
          prop: "amount",
          label: this.$t("list.total_parking_number"),
          // label: this.$t("list.total_parking_number"),
          width: "",
        },
        {
          prop: "commercialStatusName",
          label: this.$t("list.Commercial_status"),
          width: "",
        },
      ],
    };
  },
  methods: {
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        if (data.parkTypeName == "封闭车场") {
          this.$router.push({ path: "/addClosePark", query: data });
        } else {
          this.$router.push({ path: "/addNoClosePark", query: data });
        }
      }
      if (cmd == "b") {
        if (data.slaveRelation == "父车场") {
          this.$router.push({ path: "/noCloseParentDetail", query: data });
        } else {
          this.$router.push({ path: "/noCloseChildDetail", query: data });
        }
      }
      if (cmd == "c") {
        this.$setAuthority("parkConfig", this.authority.subPage.configure);
        // sessionStorage.subPageAuthority = JSON.stringify(this.authority.subPage.configure);
        if (data.parkTypeName == "封闭车场") {
          this.$router.push({ path: "/closeParkConfig", query: data });
        } else {
          this.$router.push({ path: "/parkConfig", query: data });
        }
      }
    },
    getChildPark() {
      let url = "/acb/2.0/park/children/" + this.$route.query.parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.tableData = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  mounted() {
    // console.log('cccccc')
    this.getChildPark();
  },
  created() {},
};
</script>

<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click="handleBack" type="primary">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <div v-for="(item, index) in helpList" :key="index" class="header_wraper">
        <div class="title-box fw600" @click="showToggle(index)">
          <img
            :src="
              item.isSubShow
                ? require('../../../assets/img/parkiconArrowBottom.png')
                : require('../../../assets/img/parkiconArrowRight.png')
            "
            alt=""
            :class="item.isSubShow ? 'isBottom' : ''"
          />
          <span>{{ item.title }}</span>
        </div>
        <div v-if="item.isSubShow" class="header_box" :key="index">
          <div key="one" v-if="index == 0">
            <el-tabs v-model="activeName2" type="card" @tab-click="handleClick">
              <el-tab-pane label="道路信息" name="first">
                <parent-detail></parent-detail>
              </el-tab-pane>
              <el-tab-pane label="路段信息" name="second">
                <child-detail :authority="authority" ref="child"></child-detail>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div key="two" v-else-if="index == 1">
            <park-comment :datas="spinner"></park-comment>
          </div>
          <div key="three" v-else>
            <owner-comment :datas="spinner"></owner-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import childDetail from "./detailComponent/childDetail";
import parentDetail from "./detailComponent/parentDetail";
import parkComment from "./detailComponent/parkComment";
import ownerComment from "./detailComponent/ownerComment";
export default {
  name: "hello",
  data() {
    return {
      activeName2: "first",
      authority: JSON.parse(sessionStorage.subPageAuthority),
      helpList: [
        {
          title: "道路详情",
          isSubShow: true,
        },
        {
          title: "车主评价详情",
          isSubShow: false,
        },
        // 	{
        // 	title: '车主评价详情',
        // 	isSubShow: false
        // }
      ],
      curCount: 0,
      spinner: [],
    };
  },
  methods: {
    handleBack() {
      this.$router.push("/parkList");
    },
    handleClick(tab, event) {
      // let that = this;
      // if (tab.name == 'second') {
      // this.$nextTick(() => {
      // that.$refs.child.getChildPark();
      // })
      // }
    },
    showToggle(num) {
      this.curCount = num;
      this.helpList.forEach((item, index) => {
        if (index == num) {
          item.isSubShow = !item.isSubShow;
        } else {
          item.isSubShow = false;
        }
      });
    },
    getSpinnerData() {
      let url = "/acb/2.0/memberParkEvaluation/spinner";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.spinner = res.value.parkEvalutionEnum || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  components: {
    parentDetail,
    childDetail,
    parkComment,
    ownerComment,
  },
  created() {
    this.getSpinnerData();
  },
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  // height 35px
  margin: 8px 24px;
.content
  overflow: hidden;
  border-radius: 4px;
  border: 0px solid #d9d9d9;
  padding-bottom: 20px;
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    float right
    .export
      font-size 12px
      .iconfont
        margin-right 0
  .btnWrapper
    text-align left
    margin-top 28px
    float left
.header_title
  width 100%
  height 42px
  line-height 42px
  background #307ecc
  color #fff
  font-size 16px
  font-weight 600
  text-indent 12px
.header_wraper
  width 100%
.header
  width 100%
  height 42px
  line-height 42px
  background #f9f9f9
  box-sizing border-box
  border 1px solid #dcdcdc
  padding-left 30px
  position relative
  cursor pointer
.header:nth-child(1)
  border-top none
.header i
 -webkit-transform-origin 50% 50%
 margin-right 2px
 display inline-block
 position absolute
 top 13px
 left 10px
.cur1
  -webkit-transition all .1s linear
  -webkit-transform rotate(90deg)
  top 14px !important
.header_box
  width 100%
  // border 1px solid #dcdcdc
  box-sizing border-box
  border-top none
  padding 10px 10px
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.transfer
  margin 0 auto
.button
  margin-bottom 15px
  text-align right
.ruleDetail
  width 500px
  height 150px
  overflow auto
  border 1px solid #bfcbd9
  border-radius 5px
  color #1f2d3d
.feeDis
  margin-left 20px
.berth-sort
  margin-top 10px

.title-box {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding-bottom: 20px;

  img {
    width: 7px;
    height: 10px;
    margin-right: 6px;
    position: relative;
    top: 2px;

    &.isBottom {
      width: 10px;
      height: 7px;
      top: 5px;
    }
  }

  span {
    font-size: 15px;
    color: #2F2F51;
  }
}

.header_wraper {
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 16px;
}

.fw600 {
  font-weight: 600;
}
</style>
<style>
.el-table .success-row {
  background: #f0f9eb !important;
}
</style>
